<template>
  <section>
    <div class="w-full">
      <Toast />
      <div class="w-full rounded-md shadow p-2">
        <div class="w-full">
          <button @click="show = !show" class="flex gap-2">
            <i v-if="show" class="pi pi-chevron-down text-blue-800 text-xs"></i>
            <i v-else class="pi pi-chevron-right text-blue-800 text-xs"></i>
            <p class="text-blue-800 font-bold">UDF</p>
          </button>
        </div>
        <div v-if="show" class="w-full">
          <div class="lg:flex">
            <div class="w-full">
              <div class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 py-2 gap-3">
                <div>
                  <div class="w-full flex">
                    <p class="text-xs text-gray-600">Diag. Principal:</p>
                  </div>
                  <div class="w-full">
                    <AutoComplete
                      class="text-xs w-full"
                      panelClass="text-xs w-20"
                      inputClass="text-xs w-full"
                      v-model="diagnosticosStore.diagnostico_ppal"
                      :suggestions="listadoDiagnosticos"
                      @complete="buscarDiagnostico(diagnosticosStore._diagnostico_ppal)"
                      @itemSelect="ordenesStore._udf.diagPpal = diagnosticosStore._diagnostico_ppal.CdCie"
                      :v-tooltip="diagnosticosStore._diagnostico_ppal"
                      placeholder="Minimo 3 caracteres para buscar"
                      field="name"
                      id="diagPpal"
                      modelValue="CdCie"
                      :forceSelection="true"
                    >
                    <template #item="slotProps">
                      <div style="text-wrap: wrap;">
                        <span>{{slotProps.item.name}}</span>
                      </div>
                    </template>
                    </AutoComplete>
                    <span v-if="!ordenesStore._udf.diagPpal && creandoOrden" class="text-red-600 text-xs italic">Este campo es requerido</span>
                  </div>
                </div>
                <div>
                  <div class="w-full flex">
                    <p class="text-xs text-gray-600">Número de autorización:</p>
                  </div>
                  <div class="w-full">
                    <InputNumber inputClass="w-full" style="height: 1.625rem" class="w-full" v-model="ordenesStore.udf.numero_autorizacion" inputId="withoutgrouping" :useGrouping="false" />
                    <span v-if="!ordenesStore._udf.numero_autorizacion && creandoOrden" class="text-red-600 text-xs italic">Este campo es requerido</span>
                  </div>
                </div>
                <div>
                  <div class="w-full flex">
                    <p class="text-xs text-gray-600">Siniestro:</p>
                  </div>
                  <div class="w-full">
                    <InputNumber inputClass="w-full" style="height: 1.625rem" class="w-full" v-model="ordenesStore.udf.siniestro" inputId="withoutgrouping" :useGrouping="false" />
                    <span v-if="!ordenesStore._udf.siniestro && creandoOrden" class="text-red-600 text-xs italic">Este campo es requerido</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { usePacientesStore } from '../../../../stores/pacientes.store'
  import { useDiagnosticosStore } from '../../../../stores/diagnosticos.store'
  import { computed, onMounted, ref } from 'vue'
  import { useOrdenesStore } from '../../../../stores/ordenes.store'
  import { useMedicamentosStore } from '../../../../stores/medicamentos.store'
  import Swal from 'sweetalert2'
  import dayjs from 'dayjs'
  export default {
    name: 'Udf',
    emits: ['pacClear'],
    setup () {
      // Stores
      const diagnosticosStore = useDiagnosticosStore()
      const ordenesStore = useOrdenesStore()
      const pacientesStore = usePacientesStore()
      const medicamentosStore = useMedicamentosStore()
      // Referencias
      const show = ref(true)
      const exoneraCuotaOpcionesArr = ref([
        {
          name: 'No',
          id: '02'
        },
        {
          name: 'Si',
          id: '01'
        }
      ])
      const showButtonEditPac = ref(false)
      // Computadas
      const listadoDiagnosticos = computed(() => diagnosticosStore._listado_diagnosticos)
      const pacienteSeleccionado = computed(() => pacientesStore._paciente_seleccionado)
      const creandoOrden = computed(() => ordenesStore._creando_orden)
      const header = computed(() => ordenesStore._header)
      // Metodos
      const buscarDiagnostico = (diagnostico) => {
        if (diagnostico.length > 2) { diagnosticosStore.obtenerDiagnosticos(diagnostico) }
      }
      const activarSelect = (id, type, value) => {
        if (!value) {
          setTimeout(() => {
            if (type === 'input') {
              document.getElementById(id).focus()
            } else {
              document.getElementById(id).click()
            }
          }, 300)
        }
      }
      const validarHeaderConMedicamentos = (n, v, tipo) => {
        if ((medicamentosStore._medicamentos_tabla.length)) {
          Swal.fire({
            title: 'Advertencia',
            text: 'Al realizar esta acción eliminaras todos los medicamentos agregados',
            showCancelButton: true,
            icon: 'warning',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              medicamentosStore.medicamentos_tabla = []
            }
          })
        }
      }
      onMounted(() => {
      })
      return {
        show,
        header,
        showButtonEditPac,
        buscarDiagnostico,
        listadoDiagnosticos,
        exoneraCuotaOpcionesArr,
        dayjs,
        creandoOrden,
        activarSelect,
        validarHeaderConMedicamentos,
        pacientesStore,
        ordenesStore,
        pacienteSeleccionado,
        diagnosticosStore
      }
    }
  }
</script>
<style scoped>
  ::v-deep(.p-dropdown ) {
    height: 1.75rem;
    padding: 0rem;
  }
  ::v-deep(.p-dropdown .p-inputtext) {
    font-size: 0.75rem;
    padding: 0.3rem;
  }
  ::v-deep(.p-inputtext) {
    font-size: 0.75rem;
    padding: 0.25rem;
  }
  ::v-deep(.p-dropdown-filter-icon) {
    display: none !important;
  }
</style>
